@import 'css1.less';
@import 'css2.less';
@import 'animate.less';
@import 'responsive.less';
@import '~typeface-roboto';


* {
    margin: 0px;
    padding: 0px;
    border: none;
    outline: none;
}

@font-face {
    font-family: Flaticon;
    src: url("assets/fonts/Flaticon.woff2") format("truetype");
    font-display: swap;
}

@media (min-width:1200px) {
    .container {
        max-width: 1200px;
        padding: 0px 15px;
    }
}

.container-fluid {
    padding: 0px;
}

.auto-container {
    position: static;
    max-width: 1200px;
    padding: 0px 15px;
    margin: 0 auto;
}

.small-container {
    max-width: 680px;
    margin: 0 auto;
}

.boxed_wrapper {
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
}

input,
button,
select,
textarea {
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    font-size: 16px;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

input {
    transition: all 500ms ease;
}

button:focus,
input:focus,
textarea:focus {
    outline: none;
    box-shadow: none;
    transition: all 500ms ease;
}

p {
    position: relative;
    font-size: 16px;
    line-height: 30px;
    font-family: 'Rubik', sans-serif;
    color: #565872;
    margin: 0px;
    transition: all 500ms ease;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    position: relative;
    font-family: 'Heebo', sans-serif;
    font-weight: 500;
    color: #253b70;
    margin: 0px;
    transition: all 500ms ease;
}


.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    width: 100%;
    z-index: 999999;
    background: white url(./assets/preloader.gif) no-repeat 50% 50%;
}

.centred {
    text-align: center;
}

.pull-left {
    float: left;
}

.pull-right {
    float: right;
}

figure {
    margin: 0px;
}

img {
    display: inline-block;
    height: auto;
    transition-delay: .1s;
    transition-timing-function: ease-in-out;
    transition-duration: .7s;
    transition-property: all;
}

@keyframes pulse {
    50% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.5);
    }
}

.word-wrap-break {
    word-wrap: break-word;
}

.align-text-nowrap {
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.theme-btn {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
    letter-spacing: .5px;
    font-family: 'Rubik', sans-serif;
    color: #fff !important;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    padding: 17px 30px;
    z-index: 1;
    transition: all 500ms ease;
    background: #da292a;
}

.theme-btn:before {
    position: absolute;
    content: '';
    background: #1c1d1f;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-duration: 0.5s;
    transition-duration: 0.5s;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
}

.theme-btn:hover:before {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.theme-btn i {
    position: relative;
    top: 2px;
    font-size: 20px;
    margin-left: 40px;
}

.sec-title {
    position: relative;
    display: block;
    margin-bottom: 30px;
}

.sec-title.text-center {
    text-align: center;
}

.sec-title span {
    position: relative;
    display: block;
    font-size: 16px;
    line-height: 26px;
    font-family: 'Heebo', sans-serif;
    font-weight: 800;
    margin-bottom: 13px;
}

.sec-title h1,
.sec-title h2 {
    position: relative;
    font-size: 30px;
    line-height: 38px;
    color: #353535;
    font-weight: 800;
    padding-bottom: 0px;
}

.sec-title.style-two h1 {
    font-size: 35px;
    line-height: 47px;
    font-weight: 500;
}

.sec-title ul {
    display: flex;
    justify-content: center;
}

.sec-title ul a {
    color: #da292a;
}


.shop-section {
    position: relative;
    background: #faf8f4;
    padding: 115px 0px 120px 0px;
}

.shop-section .icon-layer {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: repeat;
    background-position: center;
    left: 0px;
    top: 0px;
    animation: slide 100s linear infinite;
    -webkit-animation: slide 100s linear infinite;
}

@keyframes slide {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 1920px 0;
    }
}

@-webkit-keyframes slide {
    from {
        background-position: 0 0;
    }

    to {
        background-position: 1920px 0;
    }
}

.shop-section .sec-title {
    margin-bottom: 50px;
}

.shop-section .more-btn {
    position: relative;
    padding-top: 14px;
}

.shop-section .more-btn .theme-btn {
    padding: 12px 47px;
}


.sec-title.light h2 {
    color: #fff;
}

.bread-crumb li {
    position: relative;
    display: inline-block;
    font-size: 18px;
    color: #585858;
    padding-right: 7px;
    margin-right: 2px;
}

.bread-crumb li:last-child {
    padding-right: 0px;
    margin-right: 0px;
}

.bread-crumb li a {
    color: #da292a;
}

.bread-crumb li:last-child:before {
    display: none;
}

.sidebar .sidebar-widget .widget-title h3 {
    position: relative;
    display: block;
    font-size: 22px;
    line-height: 30px;
    color: #222;
    font-weight: 700;
    padding-bottom: 19px;
}

.sidebar .sidebar-widget .widget-title {
    position: relative;
    display: block;
    margin-bottom: 33px;
}

.sidebar .sidebar-widget .widget-title h3:before {
    position: absolute;
    content: '';
    background: #f8d4d4;
    width: 100%;
    height: 2px;
    left: 0px;
    bottom: 0px;
    padding-left: 30px;
}

.sidebar .category-widget {
    margin-bottom: 50px;
}

.sidebar .category-widget .category-list li {
    position: relative;
    display: block;
    margin-bottom: 22px;
    padding-left: 35px;
}

.sidebar .category-widget .category-list li:last-child {
    margin-bottom: 0px;
}

.sidebar .category-widget .category-list li a {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    font-family: 'Heebo', sans-serif;
    color: #222;
    font-weight: 700;
    opacity: 0.7;
}

.sidebar .category-widget .category-list li i {
    position: absolute;
    left: 0px;
    top: 1px;
    font-size: 16px;
    color: #da292a;
    font-weight: 700;
}

.sidebar .category-widget .category-list li a.active,
.sidebar .category-widget .category-list li a:hover {
    opacity: 1
}

.sidebar .filter-widget .widget-title {
    margin-bottom: 49px;
}

.sidebar .filter-widget {
    position: relative;
    margin-bottom: 55px;
}

.sidebar .sidebar-tags .tags-list li {
    position: relative;
    display: inline-block;
    float: left;
    font-size: 14px;
    line-height: 25px;
    margin: 0px 10px 10px 0px;
}

.sidebar .sidebar-tags .tags-list li a {
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 25px;
    font-family: 'Heebo', sans-serif;
    color: #282932;
    background: #fbe9e9;
    border-radius: 5px;
    text-align: center;
    padding: 0px 17.5px;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn.bootstrap-touchspin-down {
    position: absolute;
    height: 50px;
    width: 40px;
    background: transparent;
    padding: 0px 0px;
    left: -39px;
    top: -50px;
    border: 1px solid #a3bbc8;
    border-right: none;
    border-radius: 0px;
}

.bootstrap-touchspin .input-group-btn-vertical>.btn.bootstrap-touchspin-up {
    position: absolute;
    height: 50px;
    width: 40px;
    background: transparent;
    padding: 0px 0px;
    right: -40px;
    top: -50px;
    border: 1px solid #a3bbc8;
    border-left: none;
    margin-top: 0px;
    border-radius: 0px;
}

.bootstrap-touchspin .glyphicon-chevron-up:before {
    content: "\f067";
    font-size: 12px;
    font-style: normal;
    color: #333;
    font-weight: 700;
}

.bootstrap-touchspin .glyphicon-chevron-down:before {
    content: "\f068";
    font-size: 12px;
    font-style: normal;
    color: #333;
    font-weight: 700;
}

.bootstrap-touchspin .input-group-btn-vertical {
    position: absolute;
    width: 100%;
}

.bootstrap-touchspin .input-group-btn-vertical i {
    top: 11px;
    left: 15px;
    font-size: inherit !important;
    font-family: "FontAwesome", sans-serif;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
    margin-top: 0px;
}

.mt-0 {
    margin-top: 0px !important;
}

.scroll-top {
    background: #da292a;
}

.sidebar-info-contents .content-inner .contact-info ul li a:hover {
    color: #da292a;
}

.sidebar-info-contents .content-inner .social-box li a:hover {
    color: #da292a;
    border-color: #da292a;
}

.ui-widget-header {
    background: #da292a;
}

.range-slider .ui-state-default,
.range-slider .ui-widget-content .ui-state-default {
    background: #da292a;
}

.sidebar .sidebar-tags .tags-list li a:hover {
    color: #fff;
    background: #da292a;
}

.sidebar .post-widget .post-inner .post .image-box {
    background: #da292a;
}

.sidebar .post-widget .post-inner .post h6 a:hover {
    color: #da292a;
}



.contact-info-section .inner-container {
    background: #da292a;
}

.descTit {
    color: #DA292A;
    font-weight: 500;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px
}

.btn {
    font-size: .8125rem !important;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    appearance: none;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5;
}

body {
    --bs-body-font-size: .8125rem;
    --bs-body-font-weight: 400;
    --bs-font-sans-serif: "Roboto", sans-serif;
    font-display: swap;
    --bs-body-font-family: var(--bs-font-sans-serif);
}

.form-control {
    appearance: auto !important;
}

.loader-container {

    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100%;
    background-color: #F6F8FC;
    width: 100%;
    z-index: 999999;
}

.loader {
    width: 48px;
    height: 48px;
    background: #F6F8FC;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 6px;
    top: 10px;
    width: 12px;
    height: 12px;
    color: #FF3D00;
    background: currentColor;
    border-radius: 50%;
    box-shadow: 25px 2px, 10px 22px;
}

.pntrCrsr {
    cursor: pointer;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@media (min-width:576px) {
    .modal-dialog:has(.schmWrp) {
        // max-width: 80%;
    }
}